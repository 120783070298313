.modalGallery {
    width:100%;
    height: 60vh;
    z-index: 0;
    .swiper-wrapper{
        max-height: 100%;
        height: 100%;
        display: flex;
    }
    .swiper-slide {
        width: 100%;
        flex-shrink: 0;
        display: block;
        height: 100%;
        max-height: 100%;
        img {
            width:100%;
            display: block;
            height: 100%;
            object-fit: contain;
        }
    }
    .swiper-button-prev, .swiper-button-next{
        color: #ccc;
    }
    .swiper-pagination-bullet-active{
        background: #ffffff;
        border: 1px solid grey;
    }
}
.modalGalleryThumbs {
    margin-top:2px;
    width:100%;
    height:100%;
    //max-height: 14vh;
    z-index: 0;
    .swiper-slide {
        cursor: pointer;
        width: 100%;
        flex-shrink: 0;
        display: block;
        height: 100%;
        max-height: 100%;
        opacity: 0.6;
        img {
            width:100%;
            display: block;
            height: 100%;
            max-height: 12vh;
            object-fit: cover;
        }
    }
    .swiper-slide-thumb-active {
        opacity: 1;
    }
    .wrapper{
        max-height: 100%;
        height: 100%;
        display: flex;
    }
}
