header {
  background: #ffffff;
  border-bottom: 1px solid rgb(204, 204, 204);

  .navbar {
    padding: 8px 0;

    @media(max-width: 768px) {
      padding: 16px 0;
    }

    .container {
      display: flex;
      align-items: center;
      column-gap: 30px;
      height: 100%;

      @media(max-width: 768px) {
        justify-content: space-between;
        flex-wrap: wrap;

        .menu_collapse {
          display: block;
          flex-basis: 100%;
          flex-grow: 1
        }
      }

      .menu {
        display: flex;
        column-gap: 30px;

        @media(max-width: 768px) {
          display: none;

          &.active {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
          }
        }
      }

      .menu_toggle {
        display: none;

        &:hover {
          cursor: pointer;
        }

        @media(max-width: 768px) {
          display: flex;
        }
      }

      button {
        font-size: 14px;
        padding: 6px 12px;
        color: #fff;
        border: none;
        height: 33px;
        border-radius: 4px;
        background: #3C8BB0;

        &:hover {
          cursor: pointer;
        }
      }

      .home {
        &:hover {
          color: #000000;
        }
        @media(max-width: 768px) {
          display: none;
        }
      }

      .search {
        input {
          font-size: 14px;
          padding: 6px 12px;
          border-radius: 4px 0 0 4px;
          border: 1px solid #ccc;
          height: 33px;
        }
        button {
          border-radius: 0 4px 4px 0;
          background: $orange;


          &:hover {
            background: $orange-hover;
            cursor: pointer;
          }
        }
        .results-wraper {
          position: absolute;
          min-width: 250px;
          line-height: 16px;
          text-align: left;
          background: #fff;
          color: rgb(51, 51, 51);
          border-radius: 4px;
          border: 0.8px solid rgba(0, 0, 0, 0.15);
          box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
          padding: 8px;
          z-index: 1000;
          max-height: 500px;
          overflow: hidden;
          overflow-y: auto;
          div{
            padding: 5px;
            a:hover {
              cursor: pointer;
              color: #F15A0E;
              background: #f8f8f8;
            }
            span{
              color: #bbb;
            }
          }
        }
      }
      .logo {
        a {
          font-size: 18px;
          color: $black;

          &:hover {
            color: $hover-text;
          }
        }
      }
    }
  }
}