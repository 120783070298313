.favourites {
    &_button {
      @media(max-width: 768px) {
        display: none;
      }
      
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      margin: 2px 15px;
      background: url("../../../public/img/solid.png") no-repeat;
      background-size: 100% 100%;
      height: 40px;
      width: 46px;

      &:hover {
        cursor: pointer;

        span {
          text-decoration: underline;
        }
      }

      img {
        max-width: 40px;
        max-height: 40px;
        object-fit: cover;
        filter: invert(17%) sepia(98%) saturate(7472%) hue-rotate(353deg) brightness(90%) contrast(98%);
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: #fff;

      }
    }
  
  &_popup {
    position: fixed;
    bottom: 25px;
    left: 0;
    background: white;
    width: 100vw;
    height: 120px;
    z-index: 1000;
    box-shadow: rgb(0, 0, 0) 3px 4px 16px 0px;
  }
}