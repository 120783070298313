.listing {
  height:auto;
  .card {
    height: 100%;
    background: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;

    @media (max-width: 640px) {
      border-radius: 0;
      border: none;
      border-bottom: 1px solid #ddd;
    }

    .cover {
      position: relative;
      //aspect-ratio: 59/38;
    }

    .price {
      position: absolute;
      background: linear-gradient(to left, rgba(66, 105, 218, 0), rgb(66, 105, 218));
      width: 200px;
      color: #ffffff;
      bottom: 15px;
      height: 20px;
      font-weight: 600;
      font-size: 14px;
      padding-left: 10px;
      display: flex;
      align-items: center;
    }

    .favourite_icon {
      position: absolute;
      bottom: 15px;
      right: 10px;
      width: 25px;
      height: 25px;
      object-position: 50% 50%;
      object-fit: cover;
      filter: invert(99%) sepia(5%) saturate(1%) hue-rotate(101deg) brightness(106%) contrast(100%);

      &_active {
        position: absolute;
        bottom: 15px;
        right: 10px;
        width: 25px;
        height: 25px;
        object-position: 50% 50%;
        object-fit: cover;
        filter: invert(20%) sepia(83%) saturate(5560%) hue-rotate(355deg) brightness(110%) contrast(127%);

        &:hover {
          cursor: pointer;
        }
      }

      &:hover {
        filter: invert(20%) sepia(83%) saturate(5560%) hue-rotate(355deg) brightness(110%) contrast(127%);
        cursor: pointer;
      }

    }

    .cover_image {
      border-radius: 4px 4px 0 0 ;
      width: 100%;
      height: 185px;
      object-fit: cover;

      @media (max-width: 640px) {
        border-radius: 0;
        //height: 100%;
      }
    }

    .ribbon {
      right: -5px;
      top: -4px;
      position: absolute;
    }
    .tour360 {
      right: -1px;
      top: -4px;
      width:70px;
      position: absolute;
      //background: linear-gradient(55deg, rgba(255,255,255,0),rgba(255,255,255,0.9));
    }

    .card_body {
      font-size: 14px;
      padding: 5px 7px 7px;
      h4 {
        color: #000000;
      }
      .city_address{
        display: flex;
      }
      p {
        font-style: italic;
        margin-right: 0px;
        margin-left: auto;
      }
      .props {
        margin-top: 10px;
        display: flex;
        justify-content: flex-end;
        column-gap: 14px;

        .prop {
          display: flex;
          align-items: center;

          img {
            margin-right: 4px;
            height: 20px;
          }
        }
      }
    }
  }
}

.Map {
  //min-height: 85vh;
  min-height: 500px;
  //height:calc(100% - 30px);
  height: 85vh;
  width: 100%;
  .map-container {
    height: 100%;
    width: 100%;
  }
}

.propertyLabel{
  background-color: rgb(66, 105, 218);
  color: #ffffff;
  padding: 3px 7px 3px 7px;
  border-radius: 5px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  span{
    color: #ffffff;
    font-weight: bold;
  }
  &:hover{
    background-color: rgb(33, 55, 111);
  }
  &::after {
    position: absolute;
    
    height: 0;
    width: 0;
    left: calc(50% - 4px);
    top: 99%;
    border: 4px solid transparent;
    content: "";
    box-sizing: border-box;
    border-top-color: rgb(66, 105, 218);
  }
}
.gm-style-iw-chr{
  position: fixed;
  z-index: 1;
  right: 0px;
  top: -5px;
}
.gm-style-iw-c{
  padding-left: 0px !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
}
.gm-style-iw-d{
  overflow: hidden !important;
}
.gm-ui-hover-effect{
  display:none;
}
button.gm-ui-hover-effect{
  right: -6px !important;
}