.pagination {
  margin-top: 10px;
  ul {
    display: flex;
    column-gap: 5px;
    justify-content: center;

    li {
      a {
        display: block;
        border-radius: 4px;
        border: solid 1px #ddd;
        background: #ffffff;
        padding: 10px;
        width: 40px;
        height: 40px;
        text-align: center;

        &:hover {
          cursor: pointer;
          background: #efefef;
        }
      }
    }
  }
    .selected {
      a {
        background: #efefef;
      }
    }
}