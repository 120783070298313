$black: #777;
$hover-text: #5e5e5e;
$placeholder: #999;
$orange: #f26721;
$orange-hover: #F15A0E;
$gray: #d1d1d5;
$dark-blue: #0d4599;

$background: #fafafa;


$container-width: 90%;

$duration: 0.15s;