@import './variables';
@import './libs/normalize';

@import "./components/all";

html{
  min-height:100%;
}
body{
  min-height:100vh;
  overflow: scroll;
}

#root{
  min-height:100vh;
  height:100%;
  position: relative;
}
main{
      //min-height: 100%;
      //height: auto !important;
      //height: calc(100% - 190px);
}

.container {
  padding: 0 15px;
  max-width: 1870px;
  margin: auto;
  height:100%;
}

main .container {
  @media(max-width: 640px) {
    padding: 0;
  }
}

.listings {
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: repeat(4, 1fr);
  grid-column-gap: 5px;
  grid-row-gap: 5px;

  @media (max-width: 1700px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @media (max-width: 1500px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 910px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 640px) {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
}

.no_scroll { overflow: hidden }

//@import 'custom';
//@import 'libs/normalize';


.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #f8f9fa;
  text-align: center;
  font-family: Arial, sans-serif;
}

.not-found-title {
  font-size: 72px;
  color: #343a40;
  margin: 0;
}

.not-found-text {
  font-size: 24px;
  color: #6c757d;
  margin: 20px 0;
}

.not-found-home-link {
  font-size: 18px;
  color: #007bff;
  text-decoration: none;
  border: 1px solid #007bff;
  padding: 10px 20px;
  border-radius: 4px;
  transition: background 0.3s ease, color 0.3s ease;
}

.not-found-home-link:hover {
  background: #007bff;
  color: #fff;
}

