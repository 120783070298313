.sub_navbar {
  border-top: 1px solid rgb(204, 204, 204);

  .container {
    display: flex;
    justify-content: center;



    .filters {
      &_toggle {
        display: none;
        @media(max-width: 768px) {
          display: block;
          border: 1px solid $gray;
          border-radius: 4px;
          padding: 4px 10px;
          margin-top: 6px;
          margin-bottom: 6px;

          &:hover {
            cursor: pointer;
            background: #f8f8f8;
          }
        }
      }

      &_body {
        display: flex;
        @media(max-width: 768px) {
          display: none;
        }
      }

      &_mobile {
        display: none;

        @media(max-width: 768px) {
          display: flex;
          flex-direction: column;
          position: absolute;
          width: 100vw;
          height: 92vh;
          background: #fff;
          z-index: 999;
          top: 0;
        }

        &_header {
          display: flex;
          justify-content: space-between;
          padding: 16px;
          border-bottom: $gray 1px solid;
          align-items: center;

          h2 {
            color: rgb(42, 42, 51);
            font-size: 20px;
          }

          svg {
            color: rgb(42, 42, 51);
            width: 20px;
            height: 20px;

            &:hover {
              cursor: pointer;
            }
          }
        }
        &_list{
          max-height:350px;
          overflow:hidden;
          overflow-y:auto;
        }
        &_price{
          margin-left: 30px;
          input{
            width:100px;
          }
        }

        &_body {
          padding: 16px;
          color: rgb(42, 42, 51);
          overflow: auto;
          
          &_filter {
            margin-top: 14px;
            
            &:first-child {
              margin-top: 0;
            }
            &_header {
              display: flex;
              justify-content: space-between;
              h3 {
                color: inherit;
                font-weight: 700;
              }
            }
          }
          &_options {
            display: none;
            margin-top: 6px;
            font-size: 16px;
            font-weight: 400;

            &_active {
              display: block;
            }
          }

          &_option {
            margin-top: 10px;
            display: flex;
            align-items: center;

            input {
              width: 20px;
              height: 20px;
            }
            label {
              margin-left: 8px;
            }
          }
        }

        &_footer {
          display: flex;
          justify-content: space-between;
          gap: 24px;
          padding: 16px;
          bottom: 0;
          margin-top: auto;
          -webkit-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
          -moz-box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);
          box-shadow: 0px -2px 8px 0px rgba(0,0,0,0.2);

          button {
            padding: 14px 20px;
            width: 100%;
            border-radius: 6px;
            border: none;
            font-size: 14px;
            font-weight: 600;

            &.primary {
              background: $orange;
              color: #fff;

              &:hover {
                background: $orange-hover;
                cursor: pointer;
              }
            }

            &.secondary {
              background: transparent;
              color: $dark-blue;

              &:hover {
                text-decoration: underline;
                cursor: pointer;
              }
            }
          }
        }
      }

      display: flex;
      flex-direction: row;
      justify-content: center;
      user-select: none;

      .filter {
        &:first-child {
          .filter_header {
            border-left: 1px solid rgb(204, 204, 204);
          }
        }

        &_option {
          color: rgb(51, 51, 51);
          padding: 3px 20px;
          display: flex;
          column-gap: 8px;
          &:hover {
            background: rgb(245, 245, 245);
            label {
              color: #F15A0E;
            }
          }
        }

        &_bb_option {
          margin-top: 0px;
          margin-left: -1px;
          border-radius: 0px;
          position: relative;
          padding: 7px;
          border: 1px solid;
          font-weight: bold;
          background-color: rgb(255, 255, 255);
          border-color: rgb(167, 166, 199);
          width: 40px;
          z-index: 1;
        }
        &_bb_active {
          border: 2px solid rgb(66, 105, 218);
          padding: 6px;
          z-index: 2;
        }
        &_bb_option:hover {
          cursor: pointer;
          color: #333;
          background-color: aliceblue;
        }
        &_more_minmax {
          padding-top:8px;
          display:flex;
          width: 100%;
          span {
            width:90px;
            margin-left: 10px;
            color: rgb(51, 51, 51);
          }
          input {
            width:50px;
            height:20px;
            margin-right:5px;
          }
        }
        &_more_to_label {
          margin: 0px 5px 0px 5px;
          width: 20px !important;
        }
        &_more_checkboxes {
          margin: 8px 15px 0px 20px;
          vertical-align: middle;
          input{vertical-align: bottom;}
          label {
            margin: 0px 8px 0px 8px;
            vertical-align: bottom;
          }
        }
        &_price {
          input{
            width:100px;
          }
          button{
            border: 0px;
            color: rgb(51, 51, 51);
            font-size: 13px;
            background: #fff;
            &:hover {
              cursor: pointer;
              color: #F15A0E;
              background: rgb(245, 245, 245);
            }
          }
          &_column{
            width:50%;
          }
        }

        &_header {
          font-size: 14px;
          padding: 15px;
          border-right: 1px solid rgb(204, 204, 204);
          display: flex;
          align-items: center;
          column-gap: 2px;

          &:hover {
            cursor: pointer;
            color: #333;
          }

          svg {
            height: 12px;
            width: 12px;
          }

          &.open {
            background: rgb(231, 231, 231);
          }
        }

        &_body {
          position: absolute;
          background: #fff;
          color: rgb(51, 51, 51);
          border-radius: 4px;
          border: 0.8px solid rgba(0, 0, 0, 0.15);
          box-shadow: rgba(0, 0, 0, 0.175) 0px 6px 12px 0px;
          padding: 8px;
          z-index: 1000;
          max-height:500px;
          overflow:hidden;
          overflow-y:auto;
        }
      }
    }


  }
}

.active_filters{
  display: flex!important;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10px;
  justify-content: space-between;
  .list{
    display: flex!important;
    flex-direction: row;
    flex-wrap: wrap;
  }
  &_item{
    color: rgb(51, 51, 51);
    border-radius: 6px;
    background: #ffffff;
    border: 1px solid #adbcca;
    padding: 4px 7px;
    font-size: 13px;
    margin: 0px 0px 5px 5px;
    &_close{
      font-size: 16px;
      vertical-align: bottom;
      display: inline-flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #F15A0E;
        background: #f8f8f8;
      }
    }
  }
  &_count {
    margin-left: 20px;
    display: inline-flex;
    align-items: center;
    font-size: 12px;
    //display: inline;
    white-space: nowrap;
  }
  .map-switch{
    float:right;
    //padding-top:7px;
    a{
      font-weight: bold;
      font-size: 16px;
      border: 1px solid #eee;
      border-radius: 5px;
      padding:7px;
      background: #ffffff;
      align-self: center;
      display: flex;
      span{
        padding-left: 7px;
      }
      @media(max-width: 768px) {
        border: 1px solid #777;
      }
    }
    @media(max-width: 768px) {
      bottom: 25px;
      position: fixed;
      align-items: center;
      justify-content: center;
      z-index: 2;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}