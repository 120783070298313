footer {
  .container {
    text-align: center;
    padding:10px;
    align-items: center;
    span{
      padding:5px;
    }
    a{
      display: inline-flex;
      align-items: center;
    }
  }
}